
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  //apiUrl: 'https://wadmin.uca.edu.ar',
  apiUrl: 'https://test.admin.universidad.candoit.com.ar',
  frontUrl: 'https://test.universidad.candoit.com.ar',
  //frontUrl: 'http://localhost:4200'
};
export const emBlue = {
  url: "https://track.embluemail.com/contacts/event",
  apiKey: '2fe81bde24d64fde9a156f5ffc9b50e7',
  apiKeyPosgrado: 'YjBlNzE5NzNiOWY3NDI0N2I1ZDc4OTVkNGNjOWVlNTY='


}
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
